@import '../../../../scss/_theme-bootstrap';

.content-block-signup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  padding: 5px 15px;
  max-width: 600px;
  overflow: visible;
  height: 100%;
  position: relative;
  z-index: 0;
  @include breakpoint($portrait-up) {
    justify-content: center;
    padding: 5px 25px;
  }
  &__wrapper {
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: flex-start;
    @include breakpoint($portrait-up) {
      width: 100%;
    }
  }
  &__messages {
    position: relative;
    text-align: center;
    margin: 3px 0;
    display: none;
    .content-block-signup--active-error &,
    .content-block-signup--active-success & {
      display: block;
    }
  }
  &__error {
    font-size: 12px;
    line-height: 1;
    color: $color-error;
    span {
      display: inline-block;
    }
  }
  &__success {
    font-size: 1rem;
    line-height: 1;
  }
  &__fields {
    order: 1;
    flex: 1 0 0;
    @include pie-clearfix;
    .content-block-signup--active-success & {
      display: none;
    }
  }
  input[type='email'].content-block-signup__field {
    height: 35px;
    text-transform: none;
    color: $color-off-black;
    position: relative;
    border: 1px solid $color-off-black;
    background: transparent;
    padding: 0 10px 0;
    width: 50vw;
    @include breakpoint($portrait-up) {
      width: 300px;
    }
    &.error {
      color: $color-error;
      border-bottom: 1px solid $color-error;
    }
    &.content-block-signup--light {
      color: $color-white;
      border-color: $color-white;
    }
  }
  &__submit {
    display: inline-block;
    width: auto;
    height: auto;
    .content-block-signup--active-success & {
      display: none;
    }
  }
  &__button {
    order: 2;
    flex: 1 0 0;
    width: 100%;
    padding-#{$ldirection}: 20px;
    @include breakpoint($portrait-up) {
      padding: 0 20px 2px;
    }
  }
  &__checkbox {
    order: 3;
    flex: 1 100%;
    margin-#{$ldirection}: 25px;
    line-height: 0.8;
    @if $hit_area_update == true {
      margin-top: 8px;
    } @else {
      margin-top: 5px;
    }
    @include breakpoint($portrait-up) {
      line-height: inherit;
      margin-top: 0;
    }
    .content-block-signup--active-success & {
      display: none;
    }
    input[type='checkbox'].content-block-signup__gdpr-checkbox {
      appearance: none;
      @include swap_direction(margin, 5px 0 0 15px);
      content: '';
      border: 1px solid $color-white;
      cursor: pointer;
      height: 15px;
      width: 15px;
      #{$ldirection}: 0;
      position: absolute;
      @include breakpoint($portrait-up) {
        @include swap_direction(margin, 5px 0 0 25px);
      }
      &.error {
        border: 1px solid $color-error;
        color: $color-error;
      }
      &:checked {
        &::before {
          content: '';
          background: url('#{$base-theme-path}img/icons/src/close.svg') no-repeat;
          filter: invert(97%) sepia(87%) saturate(173%) hue-rotate(293deg) brightness(103%)
            contrast(97%);
          height: 14px;
          width: 14px;
          position: absolute;
          top: 0;
          #{$ldirection}: 0;
          font-size: 18px;
        }
      }
    }
    ~ .content-block-signup__legal-notice {
      padding-top: 0;
    }
  }
  &__privacy {
    order: 3;
    flex: 1 100%;
    line-height: get-line-height(12px, 14px);
    margin-top: 5px;
    color: $color-white;
    font-size: 12px;
    @include breakpoint($portrait-up) {
      max-width: 90%;
    }
    a {
      color: $color-white;
    }
    .content-block-signup--active-success & {
      display: none;
    }
  }
  &__gdpr {
    &-label {
      color: $color-white;
      text-transform: none;
      font-size: 12px;
      cursor: default;
    }
  }
  &__form {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    .content-block-signup--column & {
      text-align: center;
      margin: 0 auto;
      width: 333px;
    }
  }
  .content-block__line {
    margin-bottom: 6px;
    padding: 0;
  }
  p {
    padding: 0;
    margin: 0;
    line-height: 1;
  }
  &__legal-notice {
    order: 4;
    line-height: 0.7;
    color: $color-dark-brown;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    max-width: 250px;
    text-align: $rdirection;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: 30px;
    padding-top: 10px;
    @include breakpoint($portrait-up) {
      text-align: inherit;
      margin-#{$rdirection}: 0;
    }
  }
  .tooltip {
    position: relative;
    cursor: pointer;
    text-decoration: underline;
    width: 100%;
    .tooltiptext-under {
      visibility: hidden;
      max-width: 400px;
      background-color: $white;
      color: $color-gray-darker;
      border: 1px solid $color-nobel;
      border-radius: 6px;
      padding: 10px;
      position: absolute;
      z-index: 6;
      $ldirection: 0;
      opacity: 0;
      transition: opacity 0.3s;
      top: 125%;
      &::after {
        content: '';
        position: absolute;
        bottom: 100%;
        $ldirection: 17%;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent $color-gray-darker transparent;
      }
    }
    &:hover,
    &:focus {
      .tooltiptext-under {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
